import React from 'react';
import Context from '../assets/js/Context';
// import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Component from "@reactions/component";
import { Dialog, Pane, SideSheet, Spinner, FilePicker } from 'evergreen-ui';
import Cookies from "universal-cookie";
// import * as moment from "moment";
import MaterialDatatable from "material-datatable";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import host from '../assets/js/Host';
import axios from 'axios';
const cookies = new Cookies();
const columns = [
    { field: "image", name: "icon", options: { width: 120, filter: true, sort: true, } },
    { field: "name", name: "name", options: {  filter: true, sort: true, } },
    { field: "desc", name: "info", options: { width: 120, filter: true, sort: true, } },
   
  
    { field: "delete", name: "delete", options: { width: 80, filter: true, sort: true, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    viewColumns: false,
    search: false,
}
class Device extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            subs: [],
            name: '',
            price: '',
            common: '',
            description: '',
            file: [],
            spinPage: true,

        }
    }
    // var date1 = moment(document.getElementById('date1').value).format('DD/MM/YYYY');
    componentDidMount() {
        // var officeId = localStorage.getItem('officeId')
        var header = { "Content-Type": "application/json", token: cookies.get("tokenAdmin") };

        axios.get(host + `v1/Category/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data.categories.items);
                for (let i = 0; i < res.data.data.categories.items.length; i++) {

                    let obj = {
                        image: <img src={host + res.data.data.categories.items[i].items_photos[0].file} alt='' style={{ width: 120, height: 120 }} />,
                        name:res.data.data.categories.items[i].name_en,
                        desc:res.data.data.categories.items[i].description_en,
                  
                        delete: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="delete game "
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.deleteSubscriptions(res.data.data.categories.items[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'>are sure of the deleting process</span>
                                    </Dialog>
                                    <DeleteForeverIcon style={{ color: '#d87373de', cursor: 'pointer' }}
                                        onClick={() => setState({ isShown: true })} />
                                </Pane>
                            )}
                        </Component>,
                    };
                    arr.push(obj);
                }
                this.setState({
                    items: arr, spinPage: false, data: res.data.data.categories,
                });
            })
            .catch(error => {
                console.log(error)
            })
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'center',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textTransform: 'capitalize',
                }
            },

        }
    })
    deleteSubscriptions(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("tokenAdmin") };
        axios.delete(host + `v1/item/${id}`, { headers: header })
            .then(response => {
                this.componentDidMount();
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error) })
    }

    AddSubscription() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("tokenAdmin") };
        let formData = new FormData();
        formData.append("name_en",this.state.name);
        formData.append("name_ar", ' ');
        formData.append("price", 'this.state.price');
        formData.append("offer_price", 0);
        formData.append("description_en", this.state.description);
        formData.append("description_ar", ' ');
        formData.append("categoryId", this.props.match.params.id);
        for (let i = 0; i < this.state.file.length; i++) {
            formData.append("file", this.state.file[i][0]); 
        }
        

        console.log(this.state.file[0]);
        axios({ url: host + `v1/item/`, method: "POST", data: formData, headers: header })
            .then(response => {
                // this.setState({ file: [] })
                this.componentDidMount();
                toast('تمت الاضافة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response) })
    }
    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    return (
                        <div >
                            <div id='navBac'>
                                <div></div>
                                <div>{this.state.data.name_en}</div>
                            </div>
                            <div id='btnHeaderContiner'>
                                <Component initialState={{ isShown: false }}>
                                    {({ state, setState }) => (
                                        <React.Fragment>
                                            <SideSheet
                                                isShown={state.isShown}
                                                onCloseComplete={() => setState({ isShown: false })}
                                            >
                                                <div margin={40} style={{ backgroundColor: '#fafafa', height: '100vh' }}>
                                                    <div id='titlesidesheet' style={{ backgroundColor: '#2980B9', color: "#fff", textAlign: 'center' }}>Add Product</div>
                                                    <div id='inputAdd2Continer' >
                                                        <div id={'ContinerInPut'} >
                                                            <label>name </label>
                                                            <input autoComplete='off' type='text' placeholder='name' id='InputTExtDash111'
                                                                onChange={(e) => this.setState({ name: e.target.value })} />
                                                        </div>
                                                        {/* <div id={'ContinerInPut'} >
                                                            <label>price </label>
                                                            <input autoComplete='off' type='number' placeholder='price' id='InputTExtDash111'
                                                                onChange={(e) => this.setState({ price: e.target.value })} />
                                                        </div> */}
                                                        <div id={'ContinerInPut'} >
                                                            <label>description </label>
                                                            <input autoComplete='off' type='text' placeholder='description' id='InputTExtDash111'
                                                                onChange={(e) => this.setState({ description: e.target.value })} />
                                                        </div>

                                                        <div id={'ContinerInPut'} >
                                                            <label>image 1 </label>
                                                            <FilePicker multiple style={{ width: 260 }} onChange={file => {
                                                                this.state.file.push(file)
                                                            }} />
                                                        </div>
                                                        <div id={'ContinerInPut'} >
                                                            <label>image 2 </label>
                                                            <FilePicker multiple style={{ width: 260 }} onChange={file => {
                                                                this.state.file.push(file)
                                                            }} />
                                                        </div>
                                                        <div id={'ContinerInPut'} >
                                                            <label>image 3 </label>
                                                            <FilePicker multiple style={{ width: 260 }} onChange={file => {
                                                                this.state.file.push(file)
                                                            }} />
                                                        </div>
                                                        <div id={'ContinerInPut'} >
                                                            <label>image 4 </label>
                                                            <FilePicker multiple style={{ width: 260 }} onChange={file => {
                                                                this.state.file.push(file)
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div id='btnAddContiner'>
                                                        <div id='BtnAddUser' style={{ backgroundColor: '#2980B9', color: "#fff", textAlign: 'center', width: 100, float: 'center' }} onClick={() => {
                                                            this.AddSubscription()
                                                            setState({ isShown: false })
                                                        }}>Add</div>
                                                    </div>

                                                </div>
                                            </SideSheet>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <div id='BtnAddUser' style={{ width: 150, backgroundColor: '#2980B9', color: "#fff", textAlign: 'center' }} onClick={() => setState({ isShown: true })}>Add Product</div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </Component>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                            <div>
                                {!this.state.spinPage ?
                                    // <div className='DataTableContiner'>
                                    <MuiThemeProvider
                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.items} columns={columns} options={options} />
                                    </MuiThemeProvider>
                                    // </div>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                        <Spinner />
                                    </div>
                                }
                            </div>
                        </div>
                    )

                }
                }

            </Context.Consumer>
        )
    }
}

export default Device;