import React from 'react';
import ScrollToTop from './assets/js/scrolToTop';
import Context from './assets/js/Context';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NotFound from './assets/js/NotFound';
import Project from './component/Project';
import LoginDash from './component/login';
// import Cookies from "universal-cookie";
import './App.css';
import './assets/css/sidebar.css';
// import './assets/css/fl-bigmug-line.css' 
import Dash from './component/sidebar';
// const cookies = new Cookies();

class App extends React.Component {
  constructor() {
    super();
    this.state = {

    }
  }
  render() {
    return (
      <div >
        <Context.Provider value={{
          value: this.state, action: {
            // AddItem: ( value) => {
            //   this.setState({updateData:value})
            // },
          }
        }}>
          <div className="App">
            <BrowserRouter>
              <ScrollToTop>
                <Switch>
                  <Route exact path='/' component={LoginDash} />
                  <Route path='/Projects' component={Dash} />
                  <Route path='/Project/:id' component={Project} />
                  <Route exact={true} path='*' component={NotFound} />
                </Switch>
              </ScrollToTop>
            </BrowserRouter>
          </div>
        </Context.Provider>

      </div>
    );
  }
}

export default App;
